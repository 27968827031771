/* globals ENV, moment */

'use strict';

$(function () {
  const urls = {
    schema: ENV + 'v2/edi/ocoren/schema',
    ocoren: ENV + 'v2/edi/ocoren/',
    occurance_date: ENV + 'v3/orders/tracking/',
  };

  // popover for Emissor da NF
  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  const order = null;

  const params = {
    method: 'GET',
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
  };

  let loadedOccurranceDate = false;
  let loadedOccurranceResult = false;
  let isSchemaLoaded = false;
  let schema = [];

  // $('#type-pedido').on('change', function () {
  //   if (this.value === 'codigo') {
  //     $('#form-invoice').css('display', 'none');
  //     $('#form-order').css('display', 'block');
  //   }

  //   if (this.value === 'nota') {
  //     $('#form-order').css('display', 'none');
  //     $('#form-invoice').css('display', 'block');
  //   }
  // });

  const loadSchema = function () {
    const request = new Request(urls.schema, params);

    fetch(request)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        schema = data.constants.delivery_occurrence_code;
      })
      .then(function () {
        isSchemaLoaded = true;
      });
  };

  loadSchema();

  const getOcorrenResult = function (
    orderId = null,
    cnpj = null,
    nota = null,
    type
  ) {
    let searchProps = '';

    switch (type) {
      case 0:
        searchProps = '?order_id=' + orderId;
        break;
      case 1:
        searchProps = '?shipper_cnpj=' + cnpj + '&invoice_number=' + nota;
        break;
      default:
        break;
    }

    const urlByOrder = urls.ocoren + searchProps;

    loadedOccurranceResult = false;
    $('#traking-error-parent').fadeOut('fast');
    $.ajax({
      url: urlByOrder,
      context: document.body,
    })
      .done(function (data) {
        loadedOccurranceResult = true;
        checkForLoading();
        if (data.count > 0) {
          populateOcorenTable(data.results);
        } else {
          showEmptyOcoren();
        }
      })
      .fail(function () {
        if (window.location.pathname === '/rastrear-cargas.html') {
          if (nota) {
            showRedirect();
            setTimeout(() => {
              window.location.href = `http://cargobr.sinclog.com.br/Rastreamento/MgA2ADEAOAAwAA==/N/${nota}`;
            }, 5000);
          } else {
            showNotFound();
          }
        } else {
          showNotFound();
        }
      });
  };

  const getOccurranceDate = function (
    orderId = null,
    cnpj = null,
    nota = null,
    type
  ) {
    let searchProps = '';

    switch (type) {
      case 0:
        searchProps = '?order_id=' + orderId;
        break;
      case 1:
        searchProps = '?nf=' + nota + '&cnpj=' + cnpj;
        break;
      default:
        break;
    }

    const urlByOrder = urls.occurance_date + searchProps;

    loadedOccurranceDate = false;
    $('#traking-error-parent').fadeOut('fast');
    $.ajax({
      url: urlByOrder,
      context: document.body,
    })
      .done(function (data) {
        loadedOccurranceDate = true;
        checkForLoading();
        if (data.length > 0) {
          showTable();
          checkDeliveryStatus(data[0]);
        } else {
          hideTable();
          showSinclogLink();
        }
      })
      .fail(function () {
        if (window.location.pathname === '/rastrear-cargas.html') {
          if (nota) {
            showRedirect();
            setTimeout(() => {
              window.location.href = `http://cargobr.sinclog.com.br/Rastreamento/MgA2ADEAOAAwAA==/N/${nota}`;
            }, 5000);
          } else {
            showNotFound();
          }
        } else {
          showNotFound();
        }
      });
  };

  const getOccurenceByCode = function (code) {
    const ocorenText = schema[code];
    return ocorenText;
  };

  const populateOcorenTable = function (results) {
    const table = results.map(function (result) {
      return (
        '<tr><td>' +
        formatDate(result.occurred_at) +
        '</td><td>' +
        getOccurenceByCode(result.delivery_occurrence_code) +
        '</td><td>' +
        result.description +
        '</td></tr>'
      );
    });

    $('#traking-result').html(table);

    showResults();
  };

  const showRedirect = function () {
    $('#modalRedirect').modal('show');
  };

  const checkDeliveryStatus = function (data) {
    let message;
    let alertType;

    if (data.real_date_delivered) {
      message =
        '&#10003; O pedido <b>#' +
        data.order_id +
        '</b> foi entregue dia ' +
        formatDate(data.real_date_delivered) +
        '.';
      alertType = 'alert-success';
      showAlertBox(message, alertType);
    } else if (data.adjust_date_delivered) {
      message =
        'O pedido <b>#' +
        data.order_id +
        '</b> está em trânsito e tem previsão de entrega para ' +
        formatDate(data.adjust_date_delivered) +
        '.';
      alertType = 'alert-info';
      showAlertBox(message, alertType);
    } else if (data.date_delivered_marked) {
      message =
        'O pedido <b>#' +
        data.order_id +
        '</b> está em trânsito e tem previsão de entrega para ' +
        formatDate(data.date_delivered_marked) +
        '.';
      alertType = 'alert-info';
      showAlertBox(message, alertType);
    } else {
      message = 'O pedido <b>' + data.order_id + '</b> ainda não foi coletado.';
      alertType = 'alert-info';
      showAlertBox(message, alertType);
    }
  };

  const showAlertBox = function (message, alertType) {
    $('#traking-alert')
      .removeClass('alert-success alert-info alert-warning')
      .addClass(alertType)
      .html(message)
      .fadeIn('fast');
  };

  const formatDate = function (date) {
    const newDate = moment(date).format('DD/MM/YYYY');

    return newDate;
  };

  const showEmptyOcoren = function () {
    $('#traking-result').html(
      '<tr><td colspan="3">O seu pedido ainda não possui observações.</td></tr>'
    );
    showResults();
  };

  const showNotFound = function () {
    $('.rastrear__result').fadeOut('fast');
    $('.track__result').fadeOut('fast');
    $('#traking-error')
      .html(
        'Pedido não encontrado, verifique os dados informados e tente novamente!'
      )
      .parent();
    $('#traking-error-parent').removeClass('d-none').fadeIn('fast');
    $('html, body').animate(
      {
        scrollTop: $('#traking-error-parent').offset().top - 50,
      },
      700
    );
  };

  const hideTable = () => {
    $('#resultado-table').hide();
  };

  const showTable = () => {
    $('#resultado-table').show();
  };

  const showSinclogLink = function () {
    var message =
      'Não encontramos informações do seu pedido. Confira se o código foi digitado corretamente.<br /><br />Caso seja destinatário da Transportadora CARGOBR, <a href="http://app.cargobr.com/Rastreamentos/Rastreamento" target="_blank" class="custom-link" data-action="Clique" data-category="Rastreio" data-label="Rastreio Enterprise">clique aqui</a>.';
    var alertType = 'alert-info';
    showAlertBox(message, alertType);
  };

  const checkForLoading = () => {
    if (loadedOccurranceDate && loadedOccurranceResult) {
      $('#resultado-rastreio .white-box__wrapper').removeClass('is-loading');
    }
  };

  const showResults = function () {
    $('.rastrear__result').fadeIn('fast');
    $('.track__result').fadeIn('fast');
    $('.track__result').fadeIn('fast');
    $('html, body').animate(
      {
        scrollTop: $('#resultado-rastreio').offset().top - 50,
      },
      700
    );
  };

  $('#button-pedido').click(function (e) {
    e.preventDefault();
    const id = $('#cod-pedido').val();

    $('#traking-alert').fadeOut();

    if (id !== '') {
      $('#resultado-rastreio .white-box__wrapper').addClass('is-loading');
      getOcorrenResult(id, null, null, 0);
      getOccurranceDate(id, null, null, 0);
    } else {
      $('#cod-pedido').addClass('invalid-input');
    }
  });

  $('#button-nota').click(function (e) {
    e.preventDefault();

    $('#traking-alert').fadeOut();

    const nota = $('#num-nota').val();
    const cnpj = $('#cnpj').val();

    if (nota !== '' && cnpj !== '') {
      $('#resultado-rastreio .white-box__wrapper').addClass('is-loading');
      if (window.location.pathname === '/rastrear-cargas.html') {
        getOcorrenResultNew(null, cnpj, nota, 1);
      } else {
        getOcorrenResult(null, cnpj, nota, 1);
        getOccurranceDate(null, cnpj, nota, 1);
      }
    } else {
      if (nota === '') {
        $('#num-nota').addClass('invalid-input');
      }
      if (cnpj === '') {
        $('#cnpj').addClass('invalid-input');
      }
    }
  });

  $('.white-box__alert .close').click(function (e) {
    $(this).parent().fadeOut('fast');
  });

  $('.rastrear__form #cod-pedido').inputmask('********');
  $('.rastrear__form #num-nota').inputmask('999999999');
  $('.rastrear__form #cnpj').inputmask({
    mask: '99.999.999/9999-99',
  });

  $('.track__form #cod-pedido').inputmask('********');
  $('.track__form #num-nota').inputmask('999999999');
  $('.track__form #cnpj').inputmask({
    mask: '99.999.999/9999-99',
  });

  const getOcorrenResultNew = function (
    orderId = null,
    cnpj = null,
    nota = null,
    type
  ) {
    let searchProps = '';

    switch (type) {
      case 0:
        searchProps = '?order_id=' + orderId;
        break;
      case 1:
        searchProps = '?shipper_cnpj=' + cnpj + '&invoice_number=' + nota;
        break;
      default:
        break;
    }

    const urlByOrder = urls.ocoren + searchProps;

    loadedOccurranceResult = false;
    $('#traking-error-parent').fadeOut('fast');

    console.log('aqui');
    const clearCNPJ = cnpj.replace('.', '').replace('/', '').replace('-', '');

    switch (cnpj) {
      // AVG
      case '02.905.424/0069-19':
        showRedirect();
        setTimeout(() => {
          window.location.href = `http://cargobr.sinclog.com.br/Rastrear/NQAyADMANgA=/${nota}`;
        }, 3000);
        break;
      case '23.429.671/0001-78':
      case '23.429.671/0003-30':
      case '21.468.952/0001-04':
      case '04.184.779/0001-01':
        showRedirect();
        setTimeout(() => {
          window.location.href = `http://cargobr.sinclog.com.br/Rastrear/NQAyADMANgA=/?filial=${clearCNPJ}&nro=${nota}`;
        }, 3000);
        break;

      // Carrefour
      case '45.543.915/0846-95':
        showRedirect();
        setTimeout(() => {
          window.location.href = `http://cargobr.sinclog.com.br/Rastrear/MQAwADQANwAyAA==/${nota}`;
        }, 3000);
        break;
      // Carrefour - Filial
      case '45.543.915/0592-32':
        showRedirect();
        setTimeout(() => {
          window.location.href = `http://cargobr.sinclog.com.br/Rastrear/MQAwADQANwAyAA==/?filial=${clearCNPJ}&nro=${nota}`;
        }, 3000);
        break;

      // Kabum
      case '05.570.714/0008-25':
        showRedirect();
        setTimeout(() => {
          window.location.href = `http://cargobr.sinclog.com.br/Rastrear/MgA2ADEAOAAwAA==/${nota}`;
        }, 3000);
        break;

      // Mary Kay
      case '00.223.046/0004-12':
        showRedirect();
        setTimeout(() => {
          window.location.href = `http://cargobr.sinclog.com.br/Rastrear/MgA2ADEAOAAwAA==/${nota}`;
        }, 3000);
        break;
      // Mary Kay - Filial
      case '00.223.046/0001-70':
        showRedirect();
        setTimeout(() => {
          window.location.href = `http://cargobr.sinclog.com.br/Rastrear/NwA4ADUANAA=/?filial=${clearCNPJ}&nro=${nota}`;
        }, 3000);
        break;

      // Via Varejo
      case '04.221.023/0027-16':
        showRedirect();
        setTimeout(() => {
          window.location.href = `http://cargobr.sinclog.com.br/Rastrear/MgA3ADQAOAA5AA==/${nota}`;
        }, 3000);
        break;
      // Via Varejo - Filiais
      case '33.041.260/1615-08':
      case '33.041.260/0750-91':
        showRedirect();
        setTimeout(() => {
          window.location.href = `http://cargobr.sinclog.com.br/Rastrear/MgA3ADQAOAA5AA==/?filial=${clearCNPJ}&nro=${nota}`;
        }, 3000);
        break;

      default:
        $.ajax({
          url: urlByOrder,
          context: document.body,
        })
          .done(function (data) {
            loadedOccurranceResult = true;
            checkForLoading();
            if (data.count > 0) {
              populateOcorenTable(data.results);
            } else {
              showEmptyOcoren();
            }
          })
          .fail(function () {
            showNotFound();
          });
        break;
    }
  };
});
