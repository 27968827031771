$(function () {
  let togleMenu = false;

  $('#sandwich').click(function (event) {
    openMenu();
    togleMenu = !togleMenu;
  });

  $('.redirect').click(function (event) {
    localStorage.setItem('refer', 'painel');
  });

  function openMenu() {
    $('#sandwich').toggleClass('header__btn-sandwich--actived');
    $('#menu').fadeToggle('fast');
  }

  setTimeout(function () {
    $('.pipz-button').attr('data-action', 'Clique');
    $('.pipz-button').attr('data-category', 'CTA');
    $('.pipz-button').attr('data-label', 'Newsletter Home');
  }, 3000);

  $('#myModal').modal('show');
});
