/* globals AMBIENT */

function login() {
  const url = window.location.href;
  const location = window.location.hostname;

  // adding refer on localstorage
  localStorage.setItem('refer', url);

  switch (location) {
    case 'lab.cargobr.com':
      window.location.href = 'http://lab.cargobr.com/cotacao/login';
      break;

    case 'cargobr.com':
      window.location.href = 'http://cargobr.com/cotacao/login';
      break;

    default:
      window.location.href = 'http://homolog-venera.cargobr.com/cotacao/login';
      break;
  }
}
