const errorsFields = {
  address: 'endereço',
  address_number: 'nº',
  address_name: 'destinatário',
  addressee_document: 'cpf destinatário',
  adjusted: 'ajustada',

  billing: 'fatura',

  cargobr_rate: 'taxa da CargoBR',
  channel: 'meio de contato',
  company: 'empresa',
  cnpj: 'CNPJ',
  collect_executed: 'data realizada',
  collect_adjusted: 'data ajustada',
  collect_prevision: 'data prevista',
  collect_number: 'nº coleta',
  complement: 'complemento',
  company_name: 'razão social',
  cte_sent: 'cte enviado',
  cte_number: 'cte',
  correction_sent: 'carta de correção',
  collect_scheduled: 'coleta agendada',
  carrier: 'transportadora',

  destination_zipcode: 'CEP de Destino',
  due_until: 'Tempo limite para atendimento',
  description: 'descrição',
  deadline: 'previsão de entrega',
  destination: 'destino',

  executed: 'executada',

  financial_email: 'e-mail',
  first_until: 'Tempo para primeira resposta',
  file: 'arquivo',
  first_freight: 'primeiro frete',

  group: 'grupo',

  invalid_value: 'Valor inválido',
  invoice_sent: 'nf enviada',

  kind: 'tipo',
  key: 'chave NF',

  message: 'mensagem',

  name: 'Nome',
  number: 'numero',
  non_field_errors:
    'Dado inválido. Necessário um dicionário mas recebeu str.',

  origin_zipcode: 'CEP de Origem',
  order: 'pedido',
  observation: 'observação',
  order_id: 'id',
  origin: 'origem',

  priority: 'prioridade',
  prevision: 'prevista',
  phone: 'telefone',

  requester: 'solicitante',
  requester_name: 'nome',
  requester_phone: 'telefone',
  requester_email: 'email',
  registered: 'registrado',

  subject: 'assunto',
  shipper_name: 'empresa',
  shipper_document: 'cnpj',
  sender_name: 'remetente',
  sender_document: 'cnpj remetente',

  value: 'valor do frete',
  value_icms: 'valor do icms',

  zipcode: 'cep',
};
