/* eslint-disable */
/* globals ENV */

$(function() {
  const url = CARGO_BLOG + "wp-json/wp/v2/posts?per_page=3&_embed";
  const feedContainer = $(".home__feed__container").find(".home__feed-box");

  $.ajax({
    type: "GET",
    url,
    success(data) {
      data.forEach(function(post) {
        const data =
          '<a class="home__feed__news" href="' +
          post.link +
          '" target="_blank" rel="noopener" title="' +
          post.title.rendered +
          '">' +
          '<div class="home__feed__thumb">' +
          '<img src="' +
          post._embedded["wp:featuredmedia"]["0"].source_url +
          '"' +
          'alt="' +
          post.title.rendered +
          '">' +
          "</div>" +
          '<div class="home__feed__title">' +
          post.title.rendered +
          "</div>" +
          "</a>";

        feedContainer.append(data);
      });
    },
    error() {
      feedContainer.append(
        "Não foi possível carregar os últimos posts do blog."
      );
    }
  });
});
