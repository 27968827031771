/* globals ENV, Inputmask, errorsFields */

$(function () {
  const api = ENV;
  const form = $('.octa-forms__form').find('form');

  /** Apply some masks */
  Inputmask.extendDefaults({ placeholder: ' ' });

  $('.octa-forms__form input[id=phone]').inputmask({
    mask: ['(99) 9999-9999', '(99) 99999-9999']
  });
  $('.octa-forms__form input[id=order_id]');
  $('.octa-forms__form input[id=cnpj]').inputmask({
    mask: '99.999.999/9999-99'
  });

  /** Attach submit handler on form */
  form.on('submit', function (e) {
    e.preventDefault();

    const formData = $(this).serializeObject();
    const inputPhone = document.querySelector('input#phone');

    /**
     * The phone field shoul be at least 13 characters.
     * Sum city code, number and formatters characters.
     */
    if (inputPhone.value.replace(/\s/g, '').length < 13) {
      const formField = inputPhone.parentElement;
      const errorMsg = document.createElement('small');

      errorMsg.textContent = 'Digite um telefone válido.';
      formField.classList.add('octa-forms__form__fields--error');
      formField.append(errorMsg);

      return false;
    }

    /** Destroy erros before submit */
    form.find('small').each(function (i, el) {
      $(this).remove();
    });

    /** Do the POST request */
    $.ajax({
      contentType: 'application/json',
      url: api + 'v3/contact/',
      type: 'POST',
      data: JSON.stringify(formData),
      success(data) {
        form.addClass('success');
      },
      error(res) {
        const error = res.responseJSON;
        if (error) {
          error.fields.forEach(function (err) {
            const input = $('input[id=' + err.name + ']');

            if (input) {
              const formFields = $(input).parent();
              formFields.addClass('octa-forms__form__fields--error');
              formFields.append(
                '<small>' + errorsFields[err.code] + '</small>'
              );
            }
          });
        }
      }
    });

    return false;
  });
});
