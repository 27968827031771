/* globals ENV */

$(function () {
  const api = ENV;
  const idToken = localStorage.getItem('idToken');

  if (idToken) {
    $.ajax({
      contentType: 'application/json',
      data: JSON.stringify({ token: idToken }),
      url: api + 'v2/auth/introspect/',
      type: 'POST',
      success() {
        $.ajax({
          contentType: 'application/json',
          headers: { authorization: 'JWT ' + idToken },
          url: api + 'v3/accounts/userprofile/',
          type: 'GET',
          success(data) {
            const createAccountWrapper = $('#headerCreateAccount');
            const enterWrapper = $('#headerEnter');

            createAccountWrapper.html(
              '<a class="btn btn-primary header__btn header__btn--primary redirect" data-action="Clique" data-category="HeaderLink" data-label="Meu Painel" href="/painel">MEU PAINEL</a>'
            );

            enterWrapper.html(
              '<a class="username" data-action="Clique" data-category="HeaderLink" data-label="Nome do usuário" href="/painel">' +
                data.results[0].name +
                '</a>'
            );
          }
        });
      }
    });
  }
});
