/* globals CARGO_BLOG */
$(function () {
  // var url = ENV+'v3/blog/?limit=3';
  // console.log('http://localhost/project/wp-json/myplugin/v2/modal')
  var url = CARGO_BLOG + 'wp-json/to-cargobr/v1/modal';
  var feedContainer = $('body').find('.index__modal');

  console.log(feedContainer);
  $.ajax({
    type: 'GET',
    url,
    success(data) {
      // Vallidating if the modal needs to be visible
      if (data.modal === 'true') {
        feedContainer.append(
          '<div class="modal fade show" id="modalAlert" tabindex="-1" role="dialog" display="none" aria-modal="true">' +
            '<div class="modal-dialog" role="document">' +
              '<div class="modal-content from-blog-modal">' +

                '<div class="modal-header">' +
                  '<h5>' + data.title + '</h5>' +
                  '<button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>' +
                '</div>' +

                '<div class="modal-body">' +
                  htmlEnDeCode.htmlDecode(data.content) +
                '</div>' +

              '</div>' +
            '</div>' +
          '</div>'
        );

        // Oppening modal
        $('#modalAlert').modal('show');
      }
    },
    error() {
      // console.log('Houve falhas ao ver se existe alertas');
    }
  });

  var htmlEnDeCode = (function () {
    var charToEntityRegex; var entityToCharRegex; var charToEntity; var
      entityToChar;

    function resetCharacterEntities() {
      charToEntity = {};
      entityToChar = {};
      // add the default set
      addCharacterEntities({
        '&amp;': '&',
        '&gt;': '>',
        '&lt;': '<',
        '&quot;': '"',
        '&#39;': "'"
      });
    }

    function addCharacterEntities(newEntities) {
      var charKeys = []; var entityKeys = []; var key; var
        echar;
      for (key in newEntities) {
        echar = newEntities[key];
        entityToChar[key] = echar;
        charToEntity[echar] = key;
        charKeys.push(echar);
        entityKeys.push(key);
      }
      charToEntityRegex = new RegExp('(' + charKeys.join('|') + ')', 'g');
      entityToCharRegex = new RegExp('(' + entityKeys.join('|') + '|&#[0-9]{1,5};)', 'g');
    }

    function htmlEncode(value) {
      var htmlEncodeReplaceFn = function (match, capture) {
        return charToEntity[capture];
      };
      return (!value) ? value : String(value).replace(charToEntityRegex, htmlEncodeReplaceFn);
    }

    function htmlDecode(value) {
      var htmlDecodeReplaceFn = function (match, capture) {
        return (capture in entityToChar)
          ? entityToChar[capture]
          : String.fromCharCode(parseInt(capture.substr(2), 10));
      };

      return (!value) ? value : String(value).replace(entityToCharRegex, htmlDecodeReplaceFn);
    }

    resetCharacterEntities();
    return {
      htmlEncode, htmlDecode
    };
  }());
});
